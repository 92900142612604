<template>
  <div class="px-14 pb-4">
    <ClipboardInput
      title="Link"
      subTitle="Share this link, use it to collect data for payment, or operations workflow"
      ><div class="d-flex justify-space-between align-center py-1">
        <p id="fformurl" class="d-flex align-center mb-0">{{ fullFormUrl }}</p>
        <v-btn
          class="text-right mx-2"
          icon
          @click="copyTextFromElement('fformurl')"
        >
          <img :src="require('@/assets/pbot_icons/copy_icon.svg')" />
        </v-btn>
      </div>
    </ClipboardInput>
    <ClipboardInput
      class="mb-12 mt-4"
      title="Embed"
      subTitle="Use the code below to integrate this form anywhere in your website or app. Copy and paste or send to your IT personel"
    >
      <div class="d-flex py-8">
        <div class="embededText d-block">
          <p class="embed mb-0" ref="emdededContent">
            &lt;script src="https://embed.hypn.so/sdk.js" &gt;&lt;/script&gt;
          </p>
          <p class="embed mb-0">{{ embedtag }}</p>
        </div>
        <div class="d-flex align-end">
          <v-btn
            class="text-right mx-2 float-right"
            icon
            @click="copyToClipboard"
          >
            <img :src="require('@/assets/pbot_icons/copy_icon.svg')" />
          </v-btn>
        </div>
      </div>
    </ClipboardInput>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ClipboardInput from "../../clipboardInput.vue";
// import copyMixin from "../../../mixins/copy";
export default {
  name: "embedFormFields",
  props: {
    hypn_id: {
      type: String,
      required: true,
    },
  },
  components: { ClipboardInput },
  data() {
    return {
      formUrl: `https://${
        process.env.NODE_ENV === "development" ||
        process.env.NODE_ENV === "staging"
          ? "dev."
          : ""
      }hypn.so/`,
    };
  },
  computed: {
    ...mapGetters({
      singleForm: "formBuilder/getSingleForm",
    }),
    fullFormUrl() {
      return this.formUrl + this.hypn_id;
    },
    embedtag() {
      return `<span  data-hyphen-form="${this.hypn_id}">hyphen form</span>`;
    },
  },

  methods: {
    ...mapActions({ showToast: "ui/showToast" }),

    copyTextFromElement(elementID) {
      let element = document.getElementById(elementID);
      let elementText = element.textContent;
      this.copyText(elementText);
      this.showToast({
        sclass: "success",
        show: true,
        message: `copied ${elementText} to clipboard`,
        timeout: 3000,
      });
    },
    copyText(text) {
      navigator.clipboard.writeText(text);
    },

    copyToClipboard() {
      var copyText = document.querySelector(".embededText").textContent;
      this.copyText(copyText);
      this.showToast({
        sclass: "success",
        show: true,
        message: `copied ${copyText} to clipboard`,
        timeout: 3000,
      });
    },
  },

  // mixins: [copyMixin("embed")],
};
</script>

<style lang="scss" scoped>
.embed {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: rgba(25, 40, 61, 0.8);
}
</style>
